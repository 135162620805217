<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import StarRating from "vue-star-rating";
import VueEasyLightbox from "vue-easy-lightbox";
import { apiRequest } from "@/helpers/api-call";
//import {reactive} from "vue";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Form Validation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, VueEasyLightbox },
  data() {
    return {
      title: "Ajouter utilisateur",
      items: [
        {
          text: "Utilisateur",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
       ordersData: [
        {
          orderid: "#NZ1563",
          date: "28 Mar, 2020",
          billingname: "Frank Dean",
          total: "$164",
          paymentstatus: "Unpaid"
        },
        {
          orderid: "#NZ1564",
          date: "28 Mar, 2020",
          billingname: "Eddy Torres",
          total: "$141",
          paymentstatus: "Paid"
        },
        {
          orderid: "#NZ1565",
          date: "29 Mar, 2020",
          billingname: "Jamison Clark",
          total: "$123",
          paymentstatus: "Paid"
        },
        {
          orderid: "#NZ1566",
          date: "30 Mar, 2020",
          billingname: "Jewel Buckley",
          total: "$112",
          paymentstatus: "Paid"
        },
        {
          orderid: "#NZ1567",
          date: "31 Mar, 2020",
          billingname: "Jeffrey Waltz",
          total: "$105",
          paymentstatus: "Unpaid"
        },
        {
          orderid: "#NZ1568",
          date: "01 Apr, 2020",
          billingname: "Jefferson Allen",
          total: "$160",
          paymentstatus: "Chargeback"
        },
        {
          orderid: "#NZ1569",
          date: "02 Apr, 2020",
          billingname: "Paul Jones",
          total: "$183",
          paymentstatus: "Paid"
        },
        {
          orderid: "#NZ1570",
          date: "03 Apr, 2020",
          billingname: "Donald Bailey",
          total: "$146",
          paymentstatus: "Paid"
        },
        {
          orderid: "#NZ1571",
          date: "03 Apr, 2020",
          billingname: "Jimmy Barker",
          total: "$165",
          paymentstatus: "Unpaid"
        },
        {
          orderid: "#NZ1572",
          date: "04 Apr, 2020",
          billingname: "Walter Brown",
          total: "$172",
          paymentstatus: "Paid"
        },
        {
          orderid: "#NZ1573",
          date: "06 Apr, 2020",
          billingname: "James Hamilton",
          total: "$152",
          paymentstatus: "Paid"
        }
      ],
       totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5,10, 25],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "orderid", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label:"Départ" },
        { key: "total", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "paymentstatus", sortable: true, label: "Statut" },
      ],
      tokenUser: JSON.parse(sessionStorage.getItem("user")).tokenSalt,
      idUser: 0,
      fisrtname: "",
      lastname: "",
      genre: "",
      boite_postale: "",
      profession: "",
      birthday: "",
      adress: "",
      city: "",
      country: "",
      number_phone: "",
      email: "",
      password: "",
      showPassword: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      index: 0,
      user: {
        username: "",
        usertype: "",
        tel: "",
        email: "",
        levelRate: "",
        level: "",
        rating: "",
      },
      preference: {
        acceptHandicape: "",
        discutIncar: "",
        eatIncar: "",
        musicIncar: "",
        smoke: "",
        stop: "",
      },
      verify: {
        email: false,
        permis: false,
        autoMoto: false,
        image: false,
        tel: false,
        cni: false,
      },
      activity: {
        publies: 0,
        annulees: 0,
        a_venir: 0,
        effectues: 0,
        gains: 0,
        reservations_annulees: 0,
        voyages_effectues: 0,
        reservations_effectuees: 0,
      },
      allImage: {
        avatar: "",
        permis: "",
        vehicule: "",
        cni: "",
      },
      avislaisses: [],
      avisrecus: [],
      img: "",
      vue: false,
      visible: false,
      showModal:false,
      historique:false,
      picture: ["PROFILE", "CNI", "PERMIS", "VOITURE"],
      imgs: "",
      text: `
         Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.
        `,
      content: `Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid. Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus.`,
    };
  },
  validations: {
    firstname: { required },
    lastname: { required },
    genre: { required },
    number_phone: { required, numeric },
    email: { required, email },
    password: { required },

    tooltipform: {
      fname: { required },
      lname: { required },
      username: { required },
      city: { required },
      state: { required },
    },
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },
  },
  async mounted() {
    //console.log("tokenUser:", this.tokenUser);
    this.idUser = this.$route.params.idUser; 
    //Chargement des données
    const data = await apiRequest(
      "POST",
      "admin/detail-user",
      { id: this.idUser },
      false
    );

    console.log("data.data:", data.data);
    if (data && data.data) {
      //console.log("data.data:", data.data);
      this.user.username =
        data.data.personne.lastname + " " + data.data.personne.firstname;
      this.user.usertype = data.data.userType;
      this.user.tel = data.data.personne.tel;
      this.user.email = data.data.personne.email;
      this.user.levelRate = data.data.levelRate;
      this.user.level = data.data.level;
      this.user.rating = data.data.rating ? data.data.rating : "0";

      (this.verify.email = data.data.emailVerified),
        (this.verify.permis = data.data.permisVerified),
        (this.verify.autoMoto = data.data.autoMotoVerified),
        (this.verify.image = data.data.pictureVerified),
        (this.verify.tel = data.data.telephoneVerified),
        (this.verify.cni = data.data.cniVerified);

      (this.preference.acceptHandicape =
        data.data.preferences.acceptPeopleDisabilities),
        (this.preference.discutIncar = data.data.preferences.discussInCar),
        (this.preference.eatIncar = data.data.preferences.eatInCar),
        (this.preference.musicIncar = data.data.preferences.enjoyMusicInCar),
        (this.preference.smoke = data.data.preferences.smokeInCar),
        (this.preference.stop = data.data.preferences.tolerateStops);

      if (data.data.userAvatarCode !== null) {
        this.allImage.avatar =
          `http://216.158.239.208:1337/admin/image/?codeImage=` +
          data.data.userAvatarCode +
          `&token=` +
          this.tokenUser;
      } else {
        this.allImage.avatar = require("@/assets/images/users/user.png");
      }

      if (data.data.userCniCode) {
        this.allImage.cni =
          `http://216.158.239.208:1337/admin/image/?codeImage=` +
          data.data.userCniCode +
          `&token=` +
          this.tokenUser;
      } else {
        this.allImage.cni = require("@/assets/images/users/user.png");
      }

      if (data.data.vehicule) {
        if (
          data.data.vehicule.autoMotoPermisCode !== null &&
          this.tokenUser !== null
        ) {
          this.allImage.permis =
            `http://216.158.239.208:1337/admin/image/?codeImage=` +
            data.data.vehicule.autoMotoPermisCode +
            `&token=` +
            this.tokenUser;
        } else {
          this.allImage.permis = require("@/assets/images/users/user.png");
        }
      }else {
          this.allImage.permis = require("@/assets/images/users/user.png");
        }

      if (data.data.vehicule) {
        if (data.data.vehicule.autoMotoPictureCode !== null) {
          this.allImage.vehicule =
            `http://216.158.239.208:1337/admin/image/?codeImage=` +
            data.data.vehicule.autoMotoPictureCode +
            `&token=` +
            this.tokenUser;
        } else {
          this.allImage.vehicule = require("@/assets/images/users/user.png");
        }
      }else {
          this.allImage.vehicule = require("@/assets/images/users/user.png");
        }
    }

    const dataActivity = await apiRequest(
      "GET",
      "admin/trajets/activites/?id=" + this.idUser,
      undefined,
      false
    );
    //console.log("data.activity:", dataActivity.data);

    if (dataActivity && dataActivity.data) {
      console.log(dataActivity.data);
      this.activity.publies = dataActivity.data.conducteur.publie
        ? dataActivity.data.conducteur.publie
        : "0";
      this.activity.annulees = dataActivity.data.conducteur.annules
        ? dataActivity.data.conducteur.annules
        : "0";
      this.activity.a_venir = dataActivity.data.conducteur.avenir
        ? dataActivity.data.conducteur.avenir
        : "0";
      this.activity.effectues = dataActivity.data.conducteur.effectues
        ? dataActivity.data.conducteur.effectues
        : "0";
      this.activity.gains = dataActivity.data.conducteur.gains
        ? dataActivity.data.conducteur.gains
        : "0";
      this.activity.reservations_effectuees = dataActivity.data.passager
        .reserEffectues
        ? dataActivity.data.passager.reserEffectues
        : "0";
      this.activity.reservations_annulees = dataActivity.data.passager
        .reserAnnules
        ? dataActivity.data.passager.reserAnnules
        : "0";
      this.activity.voyages_effectues = dataActivity.data.passager
        .voyagesEffectues
        ? dataActivity.data.passager.voyagesEffectues
        : "0";
    }

    const avisData = await apiRequest(
      "GET",
      "admin/avis/?id=" + this.idUser,
      undefined,
      false
    );

    console.log(avisData.data)
    if (avisData && avisData.data) {
      this.avislaisses = avisData.data.avisEmis;
      this.avisrecus = avisData.data.avisRecu;

    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    verification: function() {
      console.log("ok");
    },

    ValidationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    Submit: function() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      alert("data submit");
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    toogleShowPassword() {
      var show = document.getElementById("password");
      if (this.showPassword == false) {
        this.showPassword = true;
        show.type = "text";
      } else {
        this.showPassword = false;
        show.type = "password";
      }
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    showSingle() {
      this.imgs = require("@/assets/images/small/img-2.jpg");
      this.show();
    },
    showImage(img) {
      //console.log(img.srcElement.src)
      this.imgs = img.srcElement.src;
      console.log(this.imgs);
      this.show();
    },
    show() {
      this.visible = true;
    },

    async verifyPictureRefuse(){
      const verify = await apiRequest(
        "POST",
        "dash/response-profile",
        {idUser:this.idUser,reponse:0},
        false
      );

      console.log('verify:', verify)
      if (verify && verify.status==200) {
        window.location.reload()
      }
    },

    async verifyPictureAccepte(){
      const verify = await apiRequest(
        "POST",
        "dash/response-profile",
        {idUser:this.idUser,reponse:1},
        false
      );

      console.log('verify:', verify)
      if (verify && verify.status==200) {
        window.location.reload()
      }
    },

    async verifyCniRefuse(){
      const verify = await apiRequest(
        "POST",
        "dash/response-cni",
        {idUser:this.idUser,reponse:0},
        false
      );

      console.log('verify:', verify)
      if (verify && verify.status==200) {
        window.location.reload()
      }
    },

    async verifyCniAccepte(){
      const verify = await apiRequest(
        "POST",
        "dash/response-cni",
        {idUser:this.idUser,reponse:1},
        false
      );

      console.log('verify:', verify)
      if (verify && verify.status==200) {
        window.location.reload()
      }
    },

     async verifyPermisRefuse(){
      const verify = await apiRequest(
        "POST",
        "dash/response-permis",
        {idUser:this.idUser,reponse:0},
        false
      );

      console.log('verify:', verify)
      if (verify && verify.status==200) {
        window.location.reload()
      }
    },

    async verifyPermisAccepte(){
      const verify = await apiRequest(
        "POST",
        "dash/response-permis",
        {idUser:this.idUser,reponse:1},
        false
      );

      console.log('verify:', verify)
      if (verify && verify.status==200) {
        window.location.reload()
      }
    },

     async verifyVehiculeRefuse(){
      const verify = await apiRequest(
        "POST",
        "dash/response-vehicule",
        {idUser:this.idUser,reponse:0},
        false
      );

      console.log('verify:', verify)
      if (verify && verify.status==200) {
        window.location.reload()
      }
    },

    async verifyVehiculeAccepte(){
      const verify = await apiRequest(
        "POST",
        "dash/response-vehicule",
        {idUser:this.idUser,reponse:1},
        false
      );

      console.log('verify:', verify)
      if (verify && verify.status==200) {
        window.location.reload()
      }
    },
    
      async verifyAvisRefuse(idAvis){
      const verify = await apiRequest(
        "POST",
        "admin/verif-avis",
        {idAvis:idAvis,valide:0},
        false
      ); 

       console.log('verify:', verify)
      if (verify && verify.status==200) {
        window.location.reload()
      }
      
    },

    

    async verifyAvisAccepte(idAvis){
      const verify = await apiRequest(
        "POST",
        "admin/verif-avis",
        {idAvis: idAvis,valide:1},
        false
      );

      console.log('verify:', verify)
      if (verify && verify.status==200) {
        window.location.reload()
      }
      //console.log(e)
    }

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <a class="image-popup-vertical-fit ml-5" @click="showImage">
                  <img
                    style="cursor: pointer"
                    class=" avatar-xl"
                    :src="`${this.allImage.avatar}`"
                    data-holder-rendered="true"
                  />
                  <!-- <img class="img-time-matters" :src="require(`../assets/time-comparison-${locale}.png`)"> -->
                </a>
                <vue-easy-lightbox
                  :visible="vue"
                  :imgs="img"
                  @hide="handleHide"
                ></vue-easy-lightbox>
                <div class="ml-2">
                  <div class="ml-4 mt-2">
                    {{ user.username }}
                  </div>
                  <div class="ml-4 mt-2">
                      {{user.usertype}}
                  </div>
                </div>
                
              </div>

              <div class="col-md-3">
                <!-- <div class="mt-4 mt-md-0" v-for="(item, index) in verify"
                  :key="index"> -->
               
                <div v-if="this.verify.email === true" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  E-mail non vérifié
                </div>

                <div v-if="this.verify.permis === true" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Permis vérifié
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Permis non vérifié
                </div>
                <div v-if="this.verify.autoMoto === true" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Véhicule vérifié
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Véhicule non vérifié
                </div>
                <div v-if="this.verify.image === true" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Photo de profil vérifié
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Photo de profil non vérifié
                </div>
                <div v-if="this.verify.tel === true" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Numéro de téléphone vérifié
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Numéro de téléphone non vérifié
                </div>
                <div v-if="this.verify.cni === true" class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Pièce d'identité vérifiée
                </div>
                <div v-else class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/multiply.svg"
                    width="30"
                  />
                  Pièce d'identité non vérifiée
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card">
        <div class="card-body mt-3">
          
        </div>
      </div>
    </div>
    <b-modal id="modal-backdrop" centered v-model="showModal" title="Téléverser un fichier" title-class="font-18">
      <form action="">
        <input type="file" name="" id="">
      </form>
    </b-modal>

    <b-modal size="xl" hide-footer centered v-model="historique" title="Historique" title-class="font-18">
      <b-tabs nav-class="nav-tabs-custom">
          <b-tab title-link-class="p-3">
            <template v-slot:title>
              <a class="font-weight-bold active">Historique des trajets</a>
            </template>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Afficher&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Rechercher:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table
                class="table-centered"
                :items="ordersData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(paymentstatus)="row">
                  <div
                    class="badge font-size-12"
                    :class="{'badge-soft-danger': `${row.value}` === 'Chargeback',
                        'badge-soft-success': `${row.value}` === 'Paid',
                        'badge-soft-warning': `${row.value}` === 'Unpaid'}"
                  >{{ row.value}}</div>
                </template>
                <template v-slot:cell(invoice)>
                  <button class="btn btn-light btn-rounded">
                    Invoice
                    <i class="mdi mdi-download ml-2"></i>
                  </button>
                </template>
                <template v-slot:cell(action)>
                  <a
                    href="javascript:void(0);"
                    class="mr-3 text-primary"
                    v-b-tooltip.hover
                    title="Edit"
                  >
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    class="text-danger"
                    v-b-tooltip.hover
                    title="Delete"
                  >
                    <i class="mdi mdi-trash-can font-size-18"></i>
                  </a>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :prev-text="'Précédent'" :next-text="'suivant'" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
    </b-modal>
  </Layout>
</template>

<style>
label {
  font-size: 20px;
}
.divider {
  width: 100%;
  border: solid 2px blue;
}
.blueG {
  border: solid 1px #8608b8ad;
}
.YelG {
  border: solid 1px #ff6545;
}
.text-blueG {
  color: #8608b8ad;
}
</style>
